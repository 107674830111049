<template>
  <div class="row">
    <div class="col-12">
      <div class="card card-body">
        <p v-if="loading">
          <img
            :src="require('@/assets/images/loading.svg')"
            style="width: 40px; height: 40px"
            alt=""
          />
          جاري التحميل...
        </p>
        <div class="col-12 table-responsive" v-if="!loading">
          <div class="form-group">
            <label for="">البحث</label>
            <input type="text"
              class="form-control" @keyup="enablesearch = false" @change="enablesearch = true;" v-model="search">
              <span class="text-danger">*اكتب البحث واضغط في مكان فارغ للتنفيذ* ولعرض الكل ابحث عن مسافة</span>
          </div>
          <button class="btn btn-primary btn-sm" @click="onlypaid">
            اخفاء الحسابات التجريبية</button
          ><br /><br />
          <table class="table table-hover table-bordered">
            <thead>
              <th>الاسم</th>
              <th>الهاتف</th>
              <th>تاريخ الانضمام</th>
              <th>الخطة</th>
              <th>تاريخ الإنتهاء</th>
              <th>خيارات</th>
            </thead>
            <tbody>
              <template v-for="(user, i) in users">
              <tr  :key="user._id"  v-if="(enablesearch || search =='') && (search == '' || user.name.includes(search) || user.phone.includes(search)) && (i < 50 && !enablesearch || enablesearch)">
                <td>
                  {{ user.name }}
                  <span class="text-danger fa fa-times" v-if="!user.status">
                    &nbsp;متوقف</span
                  >
                  <span
                    class="text-success fa fa-link"
                    v-if="user.reseller_mode"
                  >
                    &nbsp;مسوق</span
                  >
                </td>
                <td>
                  {{ user.phone }}
                  <span class="bg-danger text-white" v-if="!user.activated">
                    &nbsp;{{ user.otp }}&nbsp;</span
                  >
                </td>
                <td>
                  {{ user.joindate.split("T")[0] }}
                </td>
                <td>
                  <span v-if="user.plan == 0" class="text-danger">
                    تجريبي
                  </span>
                  <span v-if="user.plan == 1" class="text-primary">
                    خطة البداية
                  </span>
                  <span v-if="user.plan == 2" class="text-info">
                    الخطة الفضية
                  </span>
                  <span v-if="user.plan == 3" class="text-success">
                    الخطة الذهبية
                  </span>
                  <span v-if="user.plan == 4" class="text-success">
                    الخطة الالماسية
                  </span>
                  <span class="btn btn-sm"
                    ><i class="fa fa-bug"></i> {{ user.worker }}</span
                  >
                </td>
                <td>
                  {{ user.exdate }}
                </td>
                <td>
                  <b-dropdown
                    id="dropdown-1"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    text="خيارات"
                    variant="primary"
                    size="sm"
                  >
                    <b-dropdown-item
                      @click="$router.push('/archive#' + user._id)"
                    >
                      <i class="fa fa-archive"></i>
                      ارشيف الرسائل
                    </b-dropdown-item>
                    <b-dropdown-item
                      @click="$router.push('/orders#' + user._id)"
                    >
                      <i class="fa fa-usd"></i>
                      طبات الدفع
                    </b-dropdown-item>
                    <b-dropdown-item
                      @click="$router.push('/devices#' + user._id)"
                    >
                      <i class="fa fa-mobile"></i>
                      الأجهزة
                    </b-dropdown-item>
                    <b-dropdown-item @click="jwt(user.jwt)">
                      <i class="fa fa-key"></i>
                      JWT
                    </b-dropdown-item>
                    <b-dropdown-item v-b-modal.modal-1 @click="current = user">
                      <i class="fa fa-edit"></i>
                      تعديل
                    </b-dropdown-item>
                    <b-dropdown-item @click="deleteUser(user._id)">
                      <i class="fa fa-trash"></i>
                      حذف
                    </b-dropdown-item>
                  </b-dropdown>
                </td>
              </tr>
              </template>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <!-- basic modal -->
    <b-modal id="modal-1" title="تعديل المستخدم" hide-footer>
      <div class="form-group">
        <label for="">الاسم</label>
        <input type="text" class="form-control" v-model="current.name" />
      </div>
      <div class="form-group">
        <label for="">الهاتف</label>
        <input type="text" class="form-control" v-model="current.phone" />
      </div>
      <div class="form-group">
        <label for="">الخطة</label>
        <select class="form-control" v-model="current.plan">
          <option :value="0">تجريبي</option>
          <option :value="1">البداية</option>
          <option :value="2">الفضية</option>
          <option :value="3">الذهبية</option>
          <option :value="4">الالماسية</option>
        </select>
      </div>
      <div class="form-group">
        <label for="">عدم المطالبة بالترقية</label>
        <select class="form-control" v-model="current.noupgrade">
          <option :value="true">عدم المطالبة</option>
          <option :value="false">مطالبة</option>
        </select>
      </div>
      <div class="form-group">
        <label for="">حالة الحساب</label>
        <select class="form-control" v-model="current.status">
          <option :value="false">غير فعال</option>
          <option :value="true">فعال</option>
        </select>
      </div>
      <div class="form-group">
        <label for="">تاريخ الانتهاء</label>
        <input type="text" class="form-control" v-model="current.exdate" />
      </div>
      <div class="form-group">
        <label for="">اقصى عدد للأجهزة</label>
        <input
          type="text"
          class="form-control"
          v-model="current.allowed_devices"
        />
      </div>
      <div class="form-group">
        <label for="">السيرفر (Worker)</label>
        <input type="number" class="form-control" v-model="current.worker" />
      </div>
      <div class="form-group">
        <label for="">كلمة المرور</label>
        <input type="text" class="form-control" v-model="current.password" />
      </div>
      <div class="col-12 text-center">
        <button class="btn btn-success" @click="editUserCurrent()">
          تعديل <i class="fa fa-arrow-left"></i>
        </button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { BDropdown, BDropdownItem } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
export default {
  components: {
    BDropdown,
    BDropdownItem,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      search: '',
      current: {},
      users: [],
      loading: true,
      enablesearch: false,
      user: JSON.parse(localStorage.getItem("user")),
    };
  },
  created() {
    var g = this;
    g.getUsers();
  },
  methods: {
    getUsers() {
      var g = this;
      g.loading = true;
      $.post(api + "/admin/users/list", {
        jwt: this.user.jwt,
      })
        .then(function (r) {
          g.loading = false;
          g.users = r.response;
          if (window.location.hash) {
            g.users = g.users
              .map((x) => {
                if (x._id == window.location.hash.split("#")[1]) {
                  return x;
                }
              })
              .filter((x) => {
                return x;
              });
          }
        })
        .catch(function () {
          alert("error server or internet");
        });
    },
    onlypaid() {
      var g = this;
      var arr = [];
      g.users.forEach((element) => {
        if (element.plan != 0) {
          arr.push(element);
        }
      });
      g.users = arr;
    },
    deleteUser(id) {
      var g = this;
      if (confirm("متأكد من حذف المستخدم واجهزته؟")) {
        $.post(api + "/admin/users/delete", {
          jwt: this.user.jwt,
          id: id,
        })
          .then(function (r) {
            g.getUsers();
          })
          .catch(function () {
            alert("error server or internet");
          });
      }
    },
    jwt(jwt){
      prompt("JWT:", jwt)
    },
    editUserCurrent() {
      var g = this;
      $.post(api + "/admin/users/edit", {
        jwt: this.user.jwt,
        user: g.current,
      })
        .then(function (r) {
          $("#modal-1___BV_modal_header_ > button").click();
          g.getUsers();
        })
        .catch(function () {
          alert("error server or internet");
        });
    },
  },
};
</script>

<style>
</style>